import { IMultiSelectElement } from "../../components/MultiSelect/MultiSelect";

export const categoryOptions: IMultiSelectElement[] = [
  {
    label: "Appliances",
    value: "appliances",
  },
  {
    label: "Baby",
    value: "baby",
  },
  {
    label: "Beauty",
    value: "beauty",
  },
  {
    label: "Clothing, Luggage & Handbags",
    value: "clothingluggagehandbags",
  },
  {
    label: "Computers",
    value: "computers",
  },
  {
    label: "Health & Personal Care",
    value: "healthpersonalcare",
  },
  {
    label: "Home & Kitchen",
    value: "home&kitchen",
  },
  {
    label: "Home Improvement",
    value: "homeimprovement",
  },
  {
    label: "Jewelry, Watches & Sunglasses",
    value: "jewelrywatchessunglasses",
  },
  {
    label: "Patio, Lawn & Garden",
    value: "patiolawngarden",
  },
  {
    label: "Pets",
    value: "pets",
  },
  {
    label: "Sports & Fitness",
    value: "sportsfitness",
  },
  {
    label: "Toys & Books",
    value: "toysbooks",
  },
];
