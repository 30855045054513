const Imprint = () => {
  return (
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">Impressum</h1>
      <b>Angaben gemäß § 5 TMG:</b>
      <br />
      <p>
        Sponsearly UG (haftungsbeschränkt) Konradinstraße 20,
        <br />
        12105 Berlin
      </p>
      <br />
      <b>Kontakt</b>
      <p>
        Telefon: +49 (0) 179 4377 955
        <br />
        E-Mail: <a href="mailto:info@sponsearly.com">info@sponsearly.com</a>
        <br />
        <a href="www.sponsearly.com">www.sponsearly.com</a>
      </p>
      <br />
      <p>
        Registergericht: Amtsgericht Charlottenburg <br />
        Registernummer: HRB 272297 B <br />
        Vertretungsberechtigt Geschäftsführer: Peer Vincent Stubbe
      </p>
      <br />
      <b>
        Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
      </b>
      <p>
        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
        vor einer Verbraucherschlichtungsstelle teilzunehmen.
      </p>
    </div>
  );
};

export default Imprint;
